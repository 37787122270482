const pricing = [
  {
    billingType: "Billed Monthly | For Students",
    planName: "Monthly Starter Spark",
    description: "Join Eduzy on a monthly basis and enjoy a delightful learning experience!",
    price: "INR 249",
    billingCycle: "/Monthly",
    discount: "Get a discount of 20% on your monthly fee if you sign up now!",
  },
  {
    billingType: "Billed Quarterly | For Students",
    planName: "Quarterly Excellence",
    description: "Enroll in Eduzy term-wise and navigate exams & homework with seamless ease.",
    price: "INR 549",
    billingCycle: "/Quarterly",
    pricePerMonth: "charged at INR 183 /month",
  },
  {
    billingType: "Billed Annually | For Students",
    planName: "Annual Achiever",
    description: "Join Eduzy for a full academic year and excel smoothly in tests & assignments.",
    price: "INR 1599",
    billingCycle: "/Yearly",
    pricePerMonth: "charged at INR 133 /month",
  },
  {
    billingType: "Customised Plans",
    planName: "Schools/Enterprise",
    description: [
      "Personalised Plans for groups of more than 50 students",
      "Access to Teacher Dashboard to plan assignments and tests.",
      "Insights into students’ performance",
    ],
    callToAction: "Select Plan",
  },
];

export default pricing;
