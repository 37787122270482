import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Comp from "./Components/FullPage";
import ContactUs from "./Components/ContactUs";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsAndCondition from "./Components/TermsAndCondition";
import DeleteAccount from "./Components/DeleteAccount";
import SuccessPage from "./Components/SuccessPage";
import Header from "./Components/Header";
import StudentContact from "./Components/StudentContact";
import ForSchool from "./Components/ForSchool";
import PricingPage from "./Components/PricingPage";
import ForStudent from "./Components/ForStudentSlide1";
import ForStudent2 from "./Components/ForStudentSlide2";
import ForStudent3 from "./Components/ForStudentSlide3";
import Footer from "./Components/Footer";
import StudentCarousel from "./Components/StudentCarousel";

function App() {
  return (
    <Router>
      {/* Helmet should be placed here to set metadata for the entire app */}
      <Helmet>
        <title>Eduzy</title>
        <meta
          name="description"
          content="Elevate your exam readiness with our app: Rich resources, interactive AI Bot and targeted practice for optimal exam success"
        />
        <script type="application/ld+json"></script>
      </Helmet>

      {/* Routing */}
      <Routes>
        {/* <Route path="/" element={<Comp />} /> */}
        <Route path="/" element={<Comp />} />
        <Route path="/StudentContact" element={<StudentContact />} />
        <Route path="/StudentCarousel" element={<StudentCarousel />} />

        <Route path="/pricing" element={<PricingPage />} />
        {/* <Route path="/student" element={<PricingPage />} /> */}
        <Route path="/Footer" element={<Footer />} />
        <Route path="/ForStudent" element={<ForStudent />} />
        <Route path="/ForStudent2" element={<ForStudent2 />} />
        <Route path="/ForStudent3" element={<ForStudent3 />} />
        <Route path="/ForSchool" element={<ForSchool />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsAndCondition" element={<TermsAndCondition />} />
        <Route path="/deleteAccount" element={<DeleteAccount />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/headers" element={<Header />} />
      </Routes>
    </Router>
  );
}

export default App;
