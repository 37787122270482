import React from "react";
import Navbar from "./Navbar";
import Headers from "../Components/Header";
import PricingPage from "./PricingPage";
import StudentCarousel from "../Components/StudentCarousel";
import ForSchool from "../Components/ForSchool";
import StudentContact from "./StudentContact";
import Footer from "./Footer";
const FullPage = () => {
  return (
    <>
      <Navbar />
      <Headers />
      <StudentCarousel />
      <ForSchool />
      <PricingPage />
      <StudentContact/>
      <Footer/>
    </>
  );
};

export default FullPage;
