import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import ForStudent from "../Components/ForStudentSlide1";
import ForStudent2 from "../Components/ForStudentSlide2";
import ForStudent3 from "../Components/ForStudentSlide3";

const StudentCarousel = () => {
  return (
    <>
      <div id="carousel" className="bg-white min-h-screen">
        <div>
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            loop={true}
            autoplay={{
              delay: 3000, // Change slides every 5 seconds
              disableOnInteraction: false, // Keeps autoplay active after interaction
            }}
          >
            <SwiperSlide>
              <ForStudent2 />
            </SwiperSlide>
            <SwiperSlide>
              <ForStudent3 />
            </SwiperSlide>
            <SwiperSlide>
              <ForStudent />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default StudentCarousel;
