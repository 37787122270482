import React from "react";
import image from "../assets/header.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header className="bg-white py-8 sm:py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center mt-20 sm:mt-40">
          {/* Left Content */}
          <div className="w-full md:w-1/2 text-center md:text-left space-y-8 sm:space-y-16 mb-8 md:mb-0">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-gray-900">
              <span className="block">Personalized Learning for</span>
              <span className="block mt-2 sm:mt-4">Every Student</span>
            </h1>

            <p className="text-base sm:text-lg md:text-xl text-black-900 leading-relaxed">
              <span className="block">
                Are you a student wanting to transform the way you <br className="hidden sm:inline" /> study?
              </span>
              <span className="block mt-3 sm:mt-5">
                Or a school seeking tools to empower teachers and <br className="hidden sm:inline" />
                inspire students?
              </span>
            </p>
            <div className="mt-6 sm:mt-8 flex flex-col sm:flex-row sm:space-x-4 md:space-x-7 space-y-4 sm:space-y-0">
              <Link to="/StudentCarousel" className="w-full sm:w-auto">
                <button className="w-full sm:w-auto px-6 sm:px-10 md:px-14 py-3 bg-blue-600 text-white text-base sm:text-lg font-medium rounded-2xl hover:bg-blue-700 shadow-md transition">
                  Get started for free <br /> (for students)
                </button>
              </Link>
              <Link to="/ForSchool" className="w-full sm:w-auto">
                <button className="w-full sm:w-auto px-6 sm:px-10 md:px-14 py-3 bg-blue-600 text-white text-base sm:text-lg font-medium rounded-2xl hover:bg-blue-700 shadow-md transition">
                  Get A Demo <br /> (for schools)
                </button>
              </Link>
            </div>
          </div>

          {/* Right Image */}
          <div className="w-full md:w-1/2 flex justify-center">
            <img
              src={image || "/placeholder.svg"}
              alt="Student studying"
              className="rounded-full w-70 h-70 sm:w-80 sm:h-80 md:w-96 md:h-96 lg:w-[35rem] lg:h-[35rem] shadow-xl object-cover"
            />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

