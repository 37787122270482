// import Navbar from "./Navbar";
import image from "../assets/student3.png";

const ForStudent3 = () => {
  return (
    <>
      {/* <Navbar /> */}
      <div className="bg-white min-h-screen">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 lg:py-16">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-12 lg:gap-28 mt-24">
            {/* Left Section - Image */}
            <div className="relative w-full lg:w-1/2">
              <div className="relative w-full aspect-square max-w-[600px] mx-auto">
                <img
                  src={image}
                  alt="Students enjoying learning"
                  className="rounded-full w-full h-full object-cover shadow-xl"
                />
              </div>
            </div>

            {/* Right Section - Content */}
            <div className="flex flex-col space-y-6 w-full lg:w-1/2">
              <h1 className="text-5xl lg:text-6xl font-bold text-blue-600">
                For Students
              </h1>
              <h2 className="text-2xl lg:text-3xl text-blue-600">
                What does Eduzy do for you?
              </h2>
              <div className="h-1 w-full bg-blue-600"></div>
              <h3 className="text-2xl lg:text-3xl text-blue-600 font-medium">
                Clear your Doubts Instantly
              </h3>
              <p className="text-lg lg:text-xl text-blue-600">
                No more lingering questions! Instantly dissolve uncertainties
                with the app, offering real-time doubt resolution with an Ai
                Buddy which helps you keep your learning path smooth and clear.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 pt-4">
                <a
                  href="https://play.google.com/store/apps/details?id=com.classganana.BBJr&pcampaignid=web_share "
                  className="bg-blue-600 text-white text-lg px-8 py-3  hover:bg-blue-700 transition-colors inline-block text-center"
                >
                  Download Now
                </a>

                <button
                  type="button"
                  className="bg-blue-600 text-white text-lg px-8 py-3  hover:bg-blue-700 transition-colors"
                >
                  Watch Video
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForStudent3;
