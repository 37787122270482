import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import logo from "../assets/logo.png";

const Navbar = (props) => {
  const [nav, setNav] = useState(false);
  const [productDropdown, setProductDropdown] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const toggleProductDropdown = () => {
    setProductDropdown(!productDropdown);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex justify-between items-center h-20 px-6 md:px-12 text-white bg-[#2947D4] w-full fixed top-0 left-0 z-50">
      {/* Logo */}
      <div
        onClick={props.scrollToSection1}
        className="flex flex-row space-x-2 items-center cursor-pointer"
      >
        <img src={logo} alt="Logo" className="w-[45px]" />
        <h1 className="text-3xl font-bold text-white">Eduzy</h1>
      </div>

      {/* Desktop Menu */}
      <div className="hidden md:flex space-x-12 text-[20px] relative">
        {/* Product Dropdown */}
        <div className="relative group">
          <button
            onClick={toggleProductDropdown}
            className="hover:text-gray-300 focus:outline-none"
          >
            Product
          </button>
          {productDropdown && (
            <div className="absolute left-0 mt-5 w-44 bg-[#2947D4] text-white rounded-lg shadow-lg z-50">
              <ul className="space-y-2 p-3">
                <li className="hover:bg-blue-300 rounded-md p-2 cursor-pointer">
                  <button onClick={() => scrollToSection("carousel")}>
                    For Student
                  </button>
                </li>
                <li className="hover:bg-blue-300 rounded-md p-2 cursor-pointer">
                  <button onClick={() => scrollToSection("school")}>
                    For School
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>

        <button
          onClick={() => scrollToSection("pricing")}
          className="hover:text-gray-300"
        >
          Pricing
        </button>
        <a
          href="https://blogs.eduzy.in/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-300"
        >
          Blog
        </a>


        <button
          onClick={() => scrollToSection("contact")}
          className="hover:text-gray-300"
        >
          Contact
        </button>
        <button
          onClick={() => scrollToSection("studentLogin")}
          className="hover:text-gray-300"
        >
          Student Login
        </button>
      </div>

      {/* Mobile Menu */}
      <div onClick={handleNav} className="block md:hidden cursor-pointer">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <div
        className={`${nav
          ? "fixed right-0 top-20 w-[200px] h-full bg-[#2947D4] flex flex-col items-center space-y-8 text-white py-6 z-50 transition-transform"
          : "fixed right-[-100%] transition-transform"
          }`}
      >
        <button onClick={() => scrollToSection("home")}>Home</button>
        <button onClick={() => scrollToSection("features")}>Features</button>
        <button onClick={() => scrollToSection("pricing")}>Pricing</button>
        <button>
          <a href="https://blogs.eduzy.in">Blog</a>
        </button>
      </div>
    </div>
  );
};

export default Navbar;
