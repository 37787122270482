

const StudentContact = () => {
  return (
    <>

      <div id="contact" className=" min-h-screen flex items-center py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto w-full flex flex-col lg:flex-row items-start lg:items-center gap-8 lg:gap-16">
          {/* Left Section */}
          <div className="w-full lg:w-1/2 space-y-4">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-blue-700 leading-tight">
              See Eduzy in Action
            </h1>
            <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-blue-600">
              Get in touch with us
            </p>
            <div className="h-1 bg-blue-600 w-full max-w-[550px]"></div>
            <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-blue-600 max-w-[550px]">
              Complete the form to get in touch with us and we will get back to
              you to set up a demo at a convenient time!
            </p>
          </div>

          {/* Right Section */}
          <div className="w-full lg:w-1/2 bg-white shadow-md rounded-lg p-4 sm:p-6 md:p-7 mt-10">
            <form className="space-y-4 sm:space-y-6">
              {/* First and Last Name */}
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="w-full sm:w-1/2">
                  <label
                    htmlFor="firstName"
                    className="block text-gray-700 font-medium text-base sm:text-lg md:text-xl"
                  >
                    First Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="mt-1 w-full px-3 py-2 text-base sm:text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="w-full sm:w-1/2">
                  <label
                    htmlFor="lastName"
                    className="block text-gray-700 font-medium text-base sm:text-lg md:text-xl"
                  >
                    Last Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="mt-1 w-full px-3 py-2 text-base sm:text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div className="flex flex-col sm:flex-row gap-4">
                <div className="w-full sm:w-1/2">
                  <label
                    htmlFor="firstName"
                    className="block text-gray-700 font-medium text-base sm:text-lg md:text-xl"
                  >
                   Work Email<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="mt-1 w-full px-3 py-2 text-base sm:text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="w-full sm:w-1/2">
                  <label
                    htmlFor="lastName"
                    className="block text-gray-700 font-medium text-base sm:text-lg md:text-xl"
                  >
                    Phone Number<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="mt-1 w-full px-3 py-2 text-base sm:text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>

             

              {/* School Name */}
              <div>
                <label
                  htmlFor="school"
                  className="block text-gray-700 font-medium text-base sm:text-lg md:text-xl"
                >
                  School Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="school"
                  className="mt-1 w-full px-3 py-2 text-base sm:text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* State */}
              <div>
                <label
                  htmlFor="state"
                  className="block text-gray-700 font-medium text-base sm:text-lg md:text-xl"
                >
                  State<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="state"
                  className="mt-1 w-full px-3 py-2 text-base sm:text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Job Title/Role */}
              <div>
                <label
                  htmlFor="role"
                  className="block text-gray-700 font-medium text-base sm:text-lg md:text-xl"
                >
                  Job Title/Role
                </label>
                <input
                  type="text"
                  id="role"
                  className="mt-1 w-full px-3 py-2 text-base sm:text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Submit Button */}
              <div>
                <button
                  type="submit"
                  className="w-full sm:w-auto px-14 py-2 text-base sm:text-lg md:text-xl lg:text-2xl bg-blue-600 text-white hover:bg-blue-700 transition"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentContact;
