import React from "react";
import Navbar from "./Navbar";
import image from "../assets/school.png";

const ForSchool = () => {
  return (
    <>
      <Navbar />
      <div id="school" className="bg-white ">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 lg:py-16">
          <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-12 lg:gap-20">
            {/* Left Section */}
            <div className="flex flex-col space-y-6 max-w-2xl w-full lg:w-1/2">
              <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-blue-600">
                For Schools
              </h1>
              <h2 className="text-xl sm:text-2xl lg:text-3xl text-blue-600 font-medium">
                Leverage the power of AI in your classroom
              </h2>
              <div className="h-1 w-full bg-blue-600"></div>
              <div className="space-y-6">
                <p className="text-base sm:text-lg lg:text-xl text-blue-600">
                  Easily create chapter-specific homework and assessments for
                  any subject in your curriculum.
                </p>
                <p className="text-base sm:text-lg lg:text-xl text-blue-600">
                  Get instant insights into each student's performance with a
                  personalized teacher dashboard
                </p>
              </div>
              <button
                type="button"
                className="bg-blue-600 text-white text-base sm:text-lg px-8 sm:px-12 py-2 sm:py-3 hover:bg-blue-700 transition-colors w-fit"
              >
                Get A Demo
              </button>
            </div>

            {/* Right Section */}
            <div className="relative w-full lg:w-1/2 flex justify-center lg:justify-end">
              <div className="relative w-full max-w-[500px] aspect-square lg:max-w-[600px]">
                <img
                  src={image || "/placeholder.svg"}
                  alt="Students learning with teacher"
                  className="rounded-full w-full h-full object-cover shadow-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForSchool;

