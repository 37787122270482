import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import image from "../assets/linkedin.png";
import image1 from "../assets/yt.png";
import image2 from "../assets/insta.png";

const Footer = () => {
  return (
    <div className="bg-blue-800 px-8 py-12">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Left Column */}
        <div className="space-y-4">
          <div className="flex items-center space-x-1">
            <img src={logo} alt="Eduzy Logo" className="w-20 h-20" />
            <span className="text-white text-3xl font-semibold">Eduzy</span>
          </div>
          <div className="flex space-x-4 ml-5">
            <a href="https://www.linkedin.com/company/eduzy" target="_blank" rel="noreferrer">
              <img src={image} alt="LinkedIn" className="w-10 h-10 object-contain" />
            </a>
            <a href="https://www.youtube.com/eduzy" target="_blank" rel="noreferrer">
              <img src={image1} alt="YouTube" className="w-11 h-11 object-contain" />
            </a>
            <a href="https://www.instagram.com/eduzy.in/" target="_blank" rel="noreferrer">
              <img src={image2} alt="Instagram" className="w-10 h-10 object-contain" />
            </a>
          </div>
        </div>


        {/* Middle Column */}
        <div className="space-y-4">
          <Link to="/StudentCarousel" className="block text-white text-xl">
            <span className="hover:text-gray-300">Eduzy for Students</span>
          </Link>
          <Link to="/ForSchool" className="block text-white text-xl">
            <span className="hover:text-gray-300">Eduzy for School/Enterprise</span>
          </Link>
          <Link to="https://blogs.eduzy.in/" className="block text-white text-xl">
            <span className="hover:text-gray-300">Blogs</span>
          </Link>
        </div>

        {/* Right Column 1 */}
        <div className="space-y-4">
          <Link to="/StudentContact" className="block text-white text-xl">
            <span className="hover:text-gray-300">Contact Sales</span>
          </Link>
          <Link to="/pricing" className="block text-white text-xl">
            <span className="hover:text-gray-300">Pricing</span>
          </Link>
        </div>

        {/* Right Column 2 */}
        <div className="space-y-4">
          <Link to="/privacypolicy" className="block text-white text-xl">
            <span className="hover:text-gray-300">Privacy Policy</span>
          </Link>
          <Link to="/TermsAndCondition" className="block text-white text-xl">
            <span className="hover:text-gray-300">Terms & Conditions</span>
          </Link>
        </div>
      </div>

      {/* Copyright */}
      <div className="text-center text-white text-xl mt-8">
        ©2025 Eduzy. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
